import * as helperStorage from '../util/storage';
FCBH.docReady(async (e) => {
    let modal = document.querySelector("#popup_control");
    if (typeof modal ==undefined || modal === null) 
        return;
    let popupName = modal.getAttribute("pname") ?? "popupx";
    let scope = modal.getAttribute("pscope");
    let maxTimes = parseInt(modal.getAttribute("maxTimes")?? "3");
    let resetInDays = parseInt(modal.getAttribute("resetInDays")?? "7");
    if(canDisplay(popupName, scope, maxTimes, resetInDays)) {
        modal.style.display="block";
    }
});

export function canDisplay(name, scope = 'local', maxTimes = '3', resetInDays = 7) {
    const today = new Date(); 
    const pName= `${helperStorage.POPUP_INFO}/${name}`;
    const { displayTimes, expDate } = getFromScopeStorage(scope, pName) ?? {};
    const content = { displayTimes, expDate } ;
    let times = 1;
    if (content.displayTimes) {
        if (Date.parse(content.expDate) <= today) {
            content.displayTimes = 0;
            today.setDate(today.getDate() + resetInDays);
            content.expDate = today;
        }
        times += isNaN(content.displayTimes)? 0 : parseFloat(content.displayTimes);
    } else {
        today.setDate(today.getDate() + resetInDays);
        content.expDate = today;
    }
    content.displayTimes  = times;
    saveToScopeStorage(scope, pName, content);
    return times <= parseFloat(maxTimes);
 }
function getFromScopeStorage(scope, key) {
    if (scope === "session") return helperStorage.getFromSessionStorage(key);
    return FCBH.getFromStorage(key);
}
function saveToScopeStorage(scope, key, content) {
    if (scope === "session") { helperStorage.saveToSessionStorage(key, content); return;}
    FCBH.saveToStorage(key, content);
}