export const GIVE_FORM = "@FCBH/GiveForm";
export const CHECKOUT_FORM = "@FCBH/CheckoutForm";
export const PAYMENT_ERROR = "@FCBH/PaymentError";
export const POPUP_INFO = "@FCBH/PopupInfo";

export function saveToStorage(key, val) {
  window.localStorage.setItem(key, JSON.stringify(val));
}
export function getFromStorage(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

export function deleteFromStorage(key) {
  window.localStorage.removeItem(key);
}

export function saveToSessionStorage(key, val) {
  window.sessionStorage.setItem(key, JSON.stringify(val));
}
export function getFromSessionStorage(key) {
  return JSON.parse(window.sessionStorage.getItem(key));
}
